* {
  font-family: "Rubik", sans-serif;
}
:root {
  --color1: #00a9ac;
  --color2: #1d7b94;
  --color3: #c8f05d;
  --background: rgb(238, 246, 247);
  --text-primary: black;
  --text-secondary: royalblue;
  --accent: purple;
}
[data-theme="dark"] {
  --background: black;
  --text-primary: white;
  --text-secondary: grey;
  --accent: darkred;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0 !important;
  background-color: var(--background);
  overflow-x: hidden;
  width: 100vw;
}
/* LIT Education */
.lit {
  color: rgb(224, 168, 26);
}

.lit-education {
  color: white;
  font-size: 3rem;
}

/* Authentication */
.auth-error-message {
  color: red;
  margin-top: 10px;
}

.auth {
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 0 50px 0 50px !important;
  border: 2px solid var(--color2);
  border-radius: 15px;
  max-width: 515px !important;
}

.login {
  transform: translate(-50%, -50%);
}

.signup {
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 600px) {
  .auth {
    border: none;
  }
}

/* Headers */

.header-main {
  position: fixed;
  top: 0%;
  left: 0%;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: 12vh;
  background-color: #000;
  z-index: 1000;
}

.nav-items {
  list-style: none;
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.nav-item {
  display: inline-block;
  margin-right: 3vw;
}

.header-link {
  text-decoration: None;
  height: 100%;
  width: 100%;
}

.header-logo {
  margin-left: 80px;
}

.logo-div {
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
}

.logo-div:hover {
  cursor: pointer;
}

.logo-text {
  text-align: center;
  font-size: 1.2rem;
}

.logo-bold {
  font-weight: bold;
  color: var(--color2);
  margin-left: 7px;
}

.logo-italic {
  font-style: italic;
  color: var(--color1);
  margin-left: 7px;
}

/* ------MAINPAGE------ */
.title-div {
  height: 90vh;
  margin-top: 10vh;
  background: linear-gradient(45deg, var(--color1), #114b5a);
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: "title image";
  overflow: hidden;
}

.title-div-text {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-area: title;
}
.image-div-title {
  grid-area: image;
  position: relative;
}

.cloud-div {
  width: 50vw;
  height: 40vh;
  position: absolute;
  left: 0%;
  bottom: -10px;
}

.title-div-image {
  position: absolute;
  left: 0%;
  bottom: -10px;
  width: 90%;
}

.cloud {
  position: absolute;
  width: 100%;
}

.cloud-left {
  left: -25%;
  top: 13%;
}

.cloud-right {
  left: 25%;
  top: 7%;
  transform: rotate(160deg);
  width: 111%;
}

.title-text {
  color: #fff;
  font-size: 4vw;
  margin-bottom: 0px;
}

.title-image {
  width: 100%;
}

.subtitle-text {
  color: #fff;
  font-size: 2vw;
}
@media only screen and (max-width: 900px) {
  .title-div {
    grid-template-columns: 100%;
    grid-template-rows: 70% 30%;
    grid-template-areas:
      "title"
      "image";
  }

  .title-image {
    width: 75%;
  }

  .title-text {
    font-size: 3rem;
  }

  .subtitle-text {
    font-size: 1rem;
  }

  .title-div-text {
    text-align: center;
  }
  .title-div-image {
    left: 25%;
    bottom: -5px;
    width: 75%;
  }
  .cloud-left {
    left: -20%;
    top: 40%;
  }

  .cloud {
    width: 200%;
  }

  .cloud-right {
    left: 80%;
    top: 50%;
    transform: rotate(160deg);
    width: 150%;
  }
}

@media only screen and (max-width: 700px) and (min-width: 501px) {
  .title-div-image {
    left: 10%;
    bottom: -5px;
    width: 90%;
  }
}
@media only screen and (max-width: 500px) {
  .title-div-image {
    left: 10%;
    bottom: -5px;
    width: 110%;
  }
  .cloud-left {
    left: -20%;
    top: 60%;
  }

  .cloud {
    width: 150%;
  }

  .cloud-right {
    left: 75%;
    top: 60%;
    transform: rotate(160deg);
    width: 150%;
  }
}

@media only screen and (max-width: 300px) {
  .cloud-left {
    top: 70%;
  }

  .cloud-right {
    top: 70%;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 501px) {
  .cloud-left {
    top: 40%;
  }

  .cloud-right {
    top: 30%;
  }
}

.chapter-wide-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.chapter-wide {
  height: 25%;
  width: 80%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-out;
}

.chapter-wide:hover {
  transform: scale(1.1);
}

.chapter-wide-username {
  color: var(--color1);
  font-weight: bold;
  margin-left: 3vw;
  font-size: 1.5vw;
}

.chapter-wide-image {
  width: 3.75vw;
  height: 85%;
  border-radius: 4px;
  margin-left: 3vw;
}

.chapter-wide-analytics {
  color: var(--color2);
  margin-left: 4vw;
  font-size: 1vw;
}

.chapter-wide-username-container {
  width: 55%;
}

/* Footer */
.footer-container {
  background-color: black;
  padding: 2rem 0rem 3rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  width: 100%;
  /* max-height: 10vh; */
}

.footer-copyright,
.footer-links {
  color: var(--background);
}

.footer-copyright {
  border-right: 3px solid var(--background);
  padding-right: 2rem;
  height: 12vh;
}

.footer-links {
  margin-top: 3vh;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.footer-copyright-text,
.footer-links-text {
  text-align: center;
}

.GithubImage {
  background-color: transparent;
  width: 50px;
}

.footer-link {
  color: var(--background);
  text-decoration: none;
  font-size: 0.9rem;
}

.footer-logo {
  justify-content: center;
}

.footer-link:visited {
  color: var(--color1);
}

.footer-link:hover {
  color: var(--color2);
  cursor: pointer;
}

.footer-copyright-heading {
  font-size: 2.2rem;
}

@media (max-width: 500px) {
  .footer-logo {
    display: none;
  }

  .footer-copyright {
    border: none;
    padding-right: 0;
    height: auto;
  }

  .footer-container {
    padding: 0.5rem 0;
    font-size: 0.7rem;
  }

  .footer-links {
    display: none;
  }

  .footer-logo {
    margin-bottom: 2rem;
  }
}

.media-icon {
  width: 2vw !important;
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(94deg)
    brightness(110%) contrast(101%);
}

/* Who we are */
.who-we-are-container {
  width: 100%;
  display: flex;
  height: 70vh;
}

.who-we-are-img-container {
  width: 45%;
  margin-left: 5%;
}
.who-we-are-info-container {
  width: 45%;
  margin-right: 5%;
}

.who-we-are {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.who-we-are-image {
  max-width: 100%;
  max-height: 100%;
}

.who-we-are-title {
  font-size: 3.5vw;
  color: var(--color1);
}

.who-we-are-info {
  width: 65%;
  line-height: 1.2;
  font-size: 1.5vw;
  text-align: center;
  margin-top: 20px;
}

@media only screen and (max-width: 900px) {
  .who-we-are-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: auto;
  }
  .who-we-are-img-container {
    width: 100%;
    margin: 0;
  }

  .who-we-are-info-container {
    width: 100%;
    margin: 0;
  }

  .who-we-are-info {
    font-size: 1rem;
  }

  .who-we-are-title {
    font-size: 2rem;
  }
}

/* Provide */

.provide {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}

.provide-title {
  font-size: 3.5vw;
  color: var(--color1);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 3vh;
}

.provide-feature-information {
  color: #777;
  width: 70%;
  font-size: 1.2vw;
  text-align: center;
  margin-bottom: 5vh;
}

.provide-feature-title {
  font-size: 2.5vw;
  margin-bottom: 3vh;
  margin-top: 3vh;
  width: 80%;
  text-align: center;
}

.feature-mc-question {
  margin: 0 0 0 0 !important;
  font-size: 1.5vw !important;
  max-height: 90% !important;
}

.feature-radar-graph {
  box-shadow: None !important;
}

.provide-feature-use {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.provide-feature {
  display: flex;
  width: 70%;
  background-color: #fff;
  font-size: 1vw;
  margin-bottom: 5vh;
}

.provide-feature-header {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .provide-title {
    font-size: 2rem;
  }

  .provide-feature {
    flex-direction: column;
  }

  .provide-feature-header {
    width: 100%;
  }

  .provide-feature-use {
    width: 100%;
  }
  .provide-feature-title {
    font-size: 1.4rem;
  }

  .provide-feature-information {
    font-size: 0.8rem;
  }

  .provide-feature-use {
    display: none;
  }
}

/* ------DASHBOARD------ */

/* DashBoard Grid */
.dashboard {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 25% 30% 45%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "sidenav radargraph calendar"
    "sidenav linegraph linegraph";
  background-color: var(--background);
  min-height: 100vh;
}

@media only screen and (max-width: 900px) {
  .dashboard {
    grid-template-columns: 100%;
    grid-template-rows: 40% 20% 20% 20%;
    grid-template-areas:
      "sidenav"
      "radargraph"
      "calendar"
      "linegraph";
    height: 220vh;
  }
}

/* -----CHARTS----- */

.calendar-title {
  grid-area: calendar-title;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.calendar-container {
  display: flex;
  grid-area: calendar;
  justify-content: center;
  align-items: center;
}

.calendar-info-container {
  grid-area: information-right;
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.mc-activity-calendar {
  grid-area: calendar;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-heatmap {
  height: 70%;
  width: 90%;
}

.calendar-heatmap-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5%;
}

.react-calendar-heatmap .color-scale-0 {
  fill: #eee;
}
.react-calendar-heatmap .color-scale-1 {
  fill: #77dddd;
}
.react-calendar-heatmap .color-scale-2 {
  fill: #55aaaa;
}
.react-calendar-heatmap .color-scale-3 {
  fill: #338888;
}
.react-calendar-heatmap .color-scale-4 {
  fill: #115555;
}
.react-calendar-heatmap {
  overflow: unset !important;
}

.mc-calendar-div {
  justify-content: left !important;
  display: grid !important;
  grid-template-rows: 25% 75%;
  grid-template-columns: 100%;
  grid-template-areas:
    "calendar-title"
    "calendar";
}
.mc-line-graph-grid {
  grid-area: linegraph;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
}
.mc-radar-graph-grid {
  grid-area: radargraph;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mc-graph-div {
  width: 90%;
  height: 90%;
  border-radius: 15px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.graph-sizing {
  width: 90%;
  height: 90%;
}

/* SideNav */

.side-nav-divider {
  border-bottom: 2px #000 solid;
  width: 80%;
}

.side-nav-div {
  height: 100%;
  width: 100%;
  grid-area: sidenav;
}
.side-nav {
  position: fixed;
  left: 2.5%;
  top: 2.5%;
  display: flex;
  flex-direction: column;
  height: 94%;
  width: 20vw;
  background: linear-gradient(-23deg, var(--color2) 8%, #55dddd 94%);
  border-radius: 15px;
  align-items: center;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.welcome {
  color: #fff;
  font-weight: bold;
  font-size: 3vw;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 10px;
}

.welcome-name {
  color: #fff;
  font-size: 2rem;
  text-align: center;
  width: 80%;
  margin-bottom: 30px;
}

.side-nav-button {
  font-size: 1.5rem;
}

@media only screen and (max-width: 900px) {
  .side-nav {
    position: inherit;
    width: 100%;
    height: 90%;
  }

  .side-nav-div {
    width: 90%;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .welcome {
    font-size: 2rem;
  }

  .side-nav-logo-div {
    width: 90%;
  }
}

/* Exam portion of Dashboard */
.exam-header {
  margin: 0 0 20px 5%;
  width: 88%;
  box-shadow: 2px 2px 2px #aaa;
  padding: 10px 10px 10px 20px;
  font-size: 1.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.exam-name {
  width: 200px;
}

.exam-info {
  float: right;
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.total-exams {
  width: 100%;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
  /* border-radius: 15px;
    border: 3px solid #4fffd3; */
  margin-right: auto;
  margin-left: auto;
  grid-column: 2/4;
  grid-row: 1/3;
}

.score {
  float: left;
}

.exam-header-buttons {
  float: right;
  display: flex;
  width: 12rem;
  justify-content: space-between;
}

@media only screen and (max-width: 900px) {
  .total-exams {
    grid-column: 1/2;
    grid-row: 2/4;
  }
}

/* Rules */

.rules-title {
  font-size: 3rem;
  color: var(--color1);
  font-style: bold;
  margin-left: 7%;
  margin-bottom: 10px;
}

.rules {
  border: 3px #000 solid;
  border-radius: 15px;
  padding: 30px 30px 30px 30px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
}

.rules-container {
  margin-left: auto;
  padding-top: 15vh;
  margin-right: auto;
  display: block;
  flex-grow: 1;
  width: 60%;
}

/* Exam MC */
.exam-mc-header {
  position: fixed;
  background-color: #aaa;
  height: 10vh;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2;
  background-color: black;
  display: grid;
  justify-content: space-between;
  color: white;
  font-size: 3rem;
  align-items: center;
  grid-template-columns: 33% 33% 33%;
}

.exam-mc-question {
  width: 80%;
  border: 3px solid black;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  padding: 2% 3% 1% 3%;
  font-size: 1.3rem;
  background-color: #fff;
}

.exam-mc-answer {
  display: flex;
  margin-bottom: 10px;
}

.exam-mc-question-question {
  margin-bottom: 20px;
}

.exam-mc-question-number {
  font-weight: bold;
  margin-right: 10px;
}

.reasoning-correct {
  color: #00aa00;
  font-weight: bold;
}

.reasoning-incorrect {
  color: #aa0000;
  font-weight: bold;
}

.reasoning-title {
  margin-top: 10px;
}

.exam-mc {
  margin-top: 13vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.exam-mc-answer-letter-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.exam-mc-answer-letter {
  padding: 9px 9px 9px 9px;
  margin-right: 10px;
}

.exam-mc-answer-answer-div {
  display: flex;
  align-items: center;
}

.exam-mc-exam-header {
  justify-content: space-between;
}

.exam-mc-exam-name {
  margin-left: auto;
  margin-right: auto;
}

.exam-nav-item {
  justify-content: center;
  align-items: center;
  display: flex;
}

.exam-mc-header-logo {
  margin-left: 20%;
}

.exam-mc-title {
  font-size: 2.2vw;
  color: #fff;
}

.submit-button-div {
  width: 15%;
  margin-left: auto;
  margin-right: auto;
}

.timer {
  color: #fff;
  font-size: 2vw;
  text-align: center;
  width: 7vw;
}

.timer-div {
  width: 10vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 100px;
}

@media only screen and (max-width: 900px) {
  .header-logo {
    margin-left: 20px;
  }

  .exam-mc-title {
    font-size: 1.3rem;
  }
  .timer-div {
    margin-right: 30px;
  }

  .timer {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 700px) {
  .logo-text {
    font-size: 1rem;
  }
  .exam-mc-title {
    font-size: 1rem;
  }
  .timer-div {
    margin-right: 30px;
  }

  .timer {
    font-size: 1rem;
  }
}
